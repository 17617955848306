import React from "react";
import SEO from "../components/SEO";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import integrationBG from "../images/v2/home/integration-bg.svg";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import Box from "@material-ui/core/Box";
import Link from "../components/v2/Link";

const useStyles = makeStyles((theme) => ({
  integrationRoot: {
    background: `url(${integrationBG})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
  },
}));

const Li = ({ children }) => {
  const styles = {
    fontSize: "18px",
    fontWeight: "400",
  };
  return <li style={styles}>{children}</li>;
};

const TermsPage = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <SEO
        canonical="/terms-of-service"
        description="Review Pepper Cloud CRM's terms of service that provides information about account, client data protection, and control, access, and disclosure of Pepper Cloud services."
        keywords="Terms of Service,Pepper Cloud CRM,Terms of Service,Client data protection"
        pathname="/terms"
        title="Terms of Service | Pepper Cloud CRM"
      />
      <div className={classes.integrationRoot}>
        <HeaderTypography
          bgcolor="transparent"
          color="common.white"
          component="h1"
          my={4}
          textAlign={{ sm: "center", xs: "center" }}
        >
          Terms of Service
        </HeaderTypography>
      </div>
      <Container component="section">
        <style
          rules={{
            color: "yellow",
          }}
          scopeSelector="Li"
        />
        <Box component="section" mb={6}>
          <Typography variant="subtitle1">
            Last Updated: 25th November 23{" "}
          </Typography>
          <ParagraphTypography>
            <b>
              Welcome to <Link href="/">www.peppercloud.com</Link>. The Terms of
              Service contains the terms and conditions that govern all use of
              our Platform (as defined below) and Services (as defined below)
              and all content, services and/or products available on or through
              the Platform (collectively, the “Pepper Cloud Services”).
            </b>
          </ParagraphTypography>
          <ParagraphTypography>
            The Company, Pepper Cloud Pte. Ltd., reserves the right to update
            and change the Terms of Service anytime without any prior notice.
            All new features that enhance the current Service and release of new
            tools or resources, shall be subject to the Terms of Service. If and
            when you continue to use the Service after any such changes, it
            shall be considered as your consent to said changes. Violation of
            any of the below-given terms will result in the termination of your
            account. If you are opting for the Service, you agree to give your
            consent for the following.
          </ParagraphTypography>
          <br />
          <Typography variant="h5" component="h2">
            1. Definitions
          </Typography>
          <ul>
            <Li>
              <b>Account:</b> An account is the primary means for accessing and
              using Pepper Cloud Services These Terms shall be governed by the
              laws of Singapore without regard to the principles of conflicts of
              law. You hereby expressly agree to submit to the exclusive
              personal jurisdiction of the federal and state courts of Singapore
              for the purpose of resolving any dispute relating to your access
              to or use of the Service.
            </Li>
            <Li>
              <b>Additional Terms:</b> These are the provisions that are
              applicable to the Client for using a particular feature. It is
              indicated by the company in relevant areas of our Services.
            </Li>
            <Li>
              <b>Authorisation:</b> The authorisation is a set of rights and
              privileges assigned to a User by a Client.
            </Li>
            <Li>
              <b>Client:</b> He/She is a legal person who has accepted the Terms
              with the Company.
            </Li>
            <Li>
              <b>Client Data:</b> It is any digital data that is subjected to
              the Pepper Cloud Services or otherwise inserted into the System by
              the Client.
            </Li>
            <Li>
              <b>Company:</b> Company indicates Pepper Cloud Pte. Ltd.
            </Li>
            <Li>
              <b>Content:</b> It is any data that is available through Pepper
              Cloud Services or contained within the System, blogs, articles,
              documents, brochures, presentations, pictures, images, audiovisual
              works, other informational materials and any comments.
            </Li>
            <Li>
              <b>Feature:</b> It is a function or set of functions that provide
              a particular CRM capability within Pepper Cloud Services.
            </Li>
            <Li>
              <b>Fee:</b>
              It is the regular payment for using the activated account of the
              Company, Pepper Cloud Pte. Ltd.
            </Li>
            <Li>
              <b>Guidelines:</b> These are the additional rules applicable to
              specific features, applications, products, or services that may be
              posted from time to time on the Platform or otherwise made
              available on or through the Pepper Cloud Services.
            </Li>
            <Li>
              <b>Plan:</b> It is criteria related to the use and functionality
              of the Pepper Cloud Services and on which the fee is based.
            </Li>
            <Li>
              <b>Platform:</b> The customer relationship management application
              of the Company..
            </Li>
            <Li>
              <b>Products:</b> These are the goods/services that a Client is
              offering to people and organisations.
            </Li>
            <Li>
              <b>Reseller:</b> Reseller is a third party entity that resells
              Pepper Cloud services, bills the clients directly, and provides
              them with customer service.
            </Li>
            <Li>
              <b>System:</b> It is an integrated cloud computing solution for
              providing Pepper Cloud Services. This includes applications,
              software, hardware, interfaces, documentation, updates, new
              releases, and other components.
            </Li>
            <Li>
              <b>User:</b> A person granted the authorisation to use the Account
              on behalf of a Client.
            </Li>
            <Li>
              <b>Website:</b> It is a compilation of all web documents (such as
              images, HTML files) which are made available via{" "}
              <Link href="/">www.peppercloud.com</Link> or its subdomains or
              domains with identical names under other top domains and owned by
              the Company.
            </Li>
          </ul>
          <br />
          <Typography variant="h5" component="h2">
            2. Authority to enter into terms with the Company
          </Typography>
          <ul>
            <Li>
              To accept the Terms for themselves or on behalf of a Client, a
              person must have the legal capacity to do so.
            </Li>
            <Li>
              In the case of an individual, the individual must be a minimum of
              18 years of age or should have valid authorisation from their
              legal representative. In the case of a legal entity, the entity
              must be duly incorporated and in good standing.
            </Li>
            <Li>
              The Terms are accepted as soon as the person has received the
              Account creation confirmation from the Company.
            </Li>
          </ul>
          <br />
          <Typography variant="h5" component="h2">
            3. Account terms
          </Typography>
          <ul>
            <Li>
              You must be human. Accounts created or registered by bots and
              other automated methods are not allowed or permitted.
            </Li>
            <Li>
              The Company may communicate with you via email regarding your
              account, updates, news, and other issues related to your account.
              As soon as you start using our services, you are automatically
              added to our mailing list and get subscribed to our emails and
              newsletters. However, you can choose to opt-out from receiving
              emails.
            </Li>
            <Li>
              You are responsible for maintaining the safety and security of
              your CRM account and password. The Company cannot and will not be
              liable for any loss or damage from your failure to comply with
              this security obligation.
            </Li>
            <Li>
              You are held responsible for the content posted and activity that
              occurred in your account (even if the content is user generated).
              It can be live chat, help desk, phone, and others.
            </Li>
            <Li>
              We may temporarily suspend your account if you abuse our service
              in any way and will seek your cooperation in this regard.
            </Li>
            <Li>
              We reserve the right to accept or refuse any clients. We have the
              sole discretion on whether to allow you to register, renew, change
              your plan, or use our services.
            </Li>
          </ul>
          <br />
          <Typography variant="h5" component="h2">
            4. Client data
          </Typography>
          <Typography variant="h6" component="h3">
            4.1 Uploading Client data to the platform
          </Typography>
          <ul>
            <Li>
              The data uploaded by the Client into the Platform or Pepper Cloud
              system should be in compliance with the Terms and applicable laws.
            </Li>
            <Li>
              By uploading the data into the system, by the Client, the Client
              authorises the Company to process the data.
            </Li>
          </ul>
          <Typography variant="h6" component="h3">
            4.2 Protection of Client data
          </Typography>
          <ul>
            <Li>
              The Company considers the privacy of its Clients and Users
              confidential. Company’s Privacy Policy at
              <Link href="/privacy"> www.peppercloud.com/privacy </Link>
              is hereby incorporated into these Terms by reference.
            </Li>
            <Li>
              The Company will maintain the security, confidentiality, and
              integrity of the Client's data at all times to the best of their
              ability.
            </Li>
            <Li>
              The Company may use service providers to perform the Services. The
              Company will make reasonable efforts to ensure that data transfers
              to service providers meet requirements applicable to Clients’
              processing of data.
            </Li>
          </ul>
          <Typography variant="h6" component="h3">
            4.3 No guarantee of accuracy
          </Typography>
          <ul>
            <Li>
              The Company does not guarantee the accuracy of information with
              respect to the Client Data.
            </Li>
          </ul>
          <Typography variant="h6" component="h3">
            4.4 Unlawful Client data
          </Typography>
          <ul>
            <Li>
              The Company is not obliged to pre-screen, monitor or filter any
              Client Data. However, if unlawful Client Data or the action of its
              unlawful processing is discovered, the Company has the right to
              deny its publication, demand the Client to comply with terms and
              applicable laws, temporarily or permanently delete unlawful data,
              or restrict Client’s access to the data.
            </Li>
          </ul>
          <Typography variant="h6" component="h3">
            4.5 Compelled disclosure
          </Typography>
          <ul>
            <Li>
              The Company may disclose a Client’s confidential information to
              the extent compelled by law to do so. In such instances, the
              Company may provide the Client with prior notice and the Client
              shall provide the required assistance.
            </Li>
            <Li>
              If Company is compelled by law to disclose Client’s confidential
              information as a part of a civil proceeding, Client has to
              reimburse the Company for its reasonable cost of compiling and
              providing secure access.
            </Li>
          </ul>
          <br />
          <Typography variant="h5" component="h2">
            5. Services
          </Typography>
          <Typography variant="h6" component="h3">
            5.1 Use of the Pepper Cloud Services
          </Typography>
          <ul>
            <Li>
              Subject to these Terms, and the payment of the applicable service
              fee, Company grants Client and its authorised users a
              non-exclusive, non-transferable, non-sublicensable licence to use
              the Pepper Cloud Services.
            </Li>
          </ul>
          <Typography variant="h6" component="h3">
            5.2 Technical support
          </Typography>
          <ul>
            <Li>
              The Company shall provide reasonable technical support to Client
              and its authorised User at the reasonable request of the Client.
              The contacts for all enquiries of support are:
              <ul>
                <Li>
                  <Link href="/contact">contact webpage</Link>
                </Li>
                <Li>
                  e-mail:{" "}
                  <Link href="mailto:support@peppercloud.com">
                    {" "}
                    support@peppercloud.com
                  </Link>
                </Li>
              </ul>
            </Li>
            <Li>
              If the service has been purchased from a Reseller, then first-line
              technical support will be provided by the Reseller and not by the
              Company.
            </Li>
          </ul>
          <Typography variant="h6" component="h3">
            5.3 Modifications to service
          </Typography>
          <ul>
            <Li>
              The Company can modify the Pepper Cloud Services or any element
              thereof from time to time without prior notice. This includes, but
              is not limited to, rebranding Pepper Cloud services, discontinuing
              part or element of the Pepper Cloud platform temporarily or
              permanently.
            </Li>
          </ul>
          <Typography variant="h6" component="h3">
            5.4 Additional features
          </Typography>
          <ul>
            <Li>
              The company may release additional Features on the Pepper Cloud
              platform. These may be subjected to additional terms. The Client's
              use of any such Feature is subject to their acceptance of
              Additional Terms.
            </Li>
          </ul>
          <br />
          <Typography variant="h5" component="h2">
            {" "}
            6. Disclaimers: No warranty
          </Typography>

          <ul>
            <Li>
              Unless expressly stated by the company, Pepper Cloud Pte. Ltd.,
              the content and the services provided by Pepper Cloud services are
              without warranties of any kind. In accordance with applicable law,
              the Company and its partners disclaim all warranties.
            </Li>
          </ul>
          <br />
          <Typography variant="h5" component="h2">
            7. Restrictions
          </Typography>
          <Typography variant="h6" component="h3">
            7.1 Prohibited activities
          </Typography>
          <ul>
            <Li>
              Prohibited Activities
              <ul>
                <Li>
                  use the Pepper Cloud Services or any part or element thereof
                  to commit a crime, breach any applicable law.
                </Li>
                <Li>
                  duplicate, distribute, modify, adapt, hack, create derivative
                  works, reverse engineer or decompile the Pepper Cloud Services
                  or any part or element thereof, or attempt to extract the
                  source code.
                </Li>
              </ul>
            </Li>
          </ul>
          <Typography variant="h6" component="h3">
            {" "}
            7.2 Certain uses require Company consent
          </Typography>
          <ul>
            <Li>
              The Client or any User may not, without Company’s prior express
              written consent:
              <ul>
                <Li>
                  sell, resell, lease, licence, sublicence, distribute,
                  disclose, exploit or otherwise grant access to Pepper Cloud
                  Services in whole or in part to any third persons;
                </Li>
                <Li>
                  use the Pepper Cloud Services or any part or element thereof
                  in a scope, with means or for purposes other than those for
                  which their functionality was created.
                </Li>
              </ul>
            </Li>
          </ul>
          <br />
          <Typography variant="h5" component="h2">
            8. Cancellation and termination
          </Typography>
          <ul>
            <Li>
              An account can be cancelled only by the account owner. In case of
              account cancellation, you are solely responsible for the proper
              cancellation of your account. For further details on how to cancel
              your account, you can email us at{" "}
              <Link href="mailto:support@peppercloud.com">
                support@peppercloud.com
              </Link>
              .
            </Li>
            <Li>
              Account cancellations will be processed within 2-3 business days.
              Once the account is cancelled, all the account information will be
              permanently deleted. You won't be charged again after your
              official cancellation date.
            </Li>
            <Li>
              The Company, in its sole discretion, has the right to suspend or
              terminate your account and refuse any and all current or future
              use of the Service, for any reason, at any time. Such termination
              of the Service will result in the deactivation or deletion of your
              Account and the forfeiture and relinquishment of all content in
              your Account. The Company reserves the right to refuse service to
              anyone for any reason at any time.
            </Li>
          </ul>
          <br />
          <Typography variant="h5" component="h2">
            9. Indemnification
          </Typography>
          <ul>
            <Li>
              You agree to defend, indemnify and hold harmless the Company, its
              affiliates, and their representatives from any claims, losses,
              damages, liabilities, including attorney’s fees, arising out of
              your use or misuse of the Pepper Cloud Services and content.
            </Li>
          </ul>
          <br />
          <Typography variant="h5" component="h2">
            10. Intellectual Property Rights
          </Typography>
          <ul>
            <Li>
              The Pepper Cloud Services, Materials, trade names and trademarks,
              and other related elements are exclusively owned and operated by
              the Company and its partners.
            </Li>
            <Li>
              Any commercial distribution, publishing or exploitation of the
              materials created and managed by the Company is strictly
              prohibited unless you have written permission.
            </Li>
          </ul>
          <Typography variant="h6" component="h3">
            {" "}
            10.1 Content owned by the Company
          </Typography>
          <ul>
            <Li>
              The Company permits Client and its users a non-exclusive,
              non-transferable, non-sublicensable licence to download any part
              of the Content solely for your personal, non-commercial use.
            </Li>
            <Li>
              You are not permitted to copy, distribute, or publish any Content
              or any information obtained or derived from Company unless you
              have been exclusively permitted by the Company.
            </Li>
          </ul>
          <Typography variant="h6" component="h3">
            10.2 Client data
          </Typography>
          <ul>
            <Li>
              The company may use Client Data in an aggregated or anonymised
              format for research, educational and other similar purposes.
            </Li>
            <Li>
              Client expressly grants Company the right to use and analyse
              aggregate system activity data for the purposes of optimising or
              enhancing the way the Pepper Cloud Services operate.
            </Li>
          </ul>
          <Typography variant="h6" component="h3">
            10.3 Feedback
          </Typography>
          <ul>
            <Li>
              If you provide any comments, bug reports, feedback, or
              modifications for the Pepper Cloud Services, Company shall have
              the right to use such Feedback at its discretion to incorporate,
              publish, reproduce, prepare derivative works of, publicly display,
              publicly perform, and use it for any purpose.
            </Li>
            <Li>
              The company shall have the right to modify or remove the Feedback
              provided in the public areas of the website.
            </Li>
          </ul>
          <br />
          <Typography variant="h5" component="h2">
            {" "}
            11. Third-party sites, products and services
          </Typography>
          <ul>
            <Li>
              The Pepper Cloud Services may include links to other websites or
              services (“Linked Sites”) solely as a convenience to Clients. The
              company doesn’t endorse any data, information, or material
              provided on those websites.
            </Li>
            <Li>
              Community provided content is developed by third parties and not
              by the Company. Therefore, the Company is not responsible for the
              performance or damages caused by the community provided content.
            </Li>
          </ul>
          <br />
          <Typography variant="h5" component="h2">
            12. Limitation of liability
          </Typography>
          <ul>
            <Li>
              Company shall not be liable to the Client or User for any
              consequences resulting from modification of terms, modification in
              fees, modification in Content, or modification in Pepper Cloud
              services; deletion of, corruption of, or failure to store Client
              Data; any disclosure, loss or unauthorised use of the login
              credentials of Client.
            </Li>
          </ul>
          <br />
          <Typography variant="h5" component="h2">
            13. Modifications to the service and prices
          </Typography>
          <ul>
            <Li>
              The Company reserves the right to modify and discontinue, at any
              time and from time to time, temporarily or permanently, the
              Service (or any part thereof) with or without notice.
            </Li>
            <Li>
              Prices of all Services of the Company are subject to change
              without prior notice from us. The Company shall not be liable to
              you or to any third party.
            </Li>
          </ul>
          <br />
          <Typography variant="h5" component="h2">
            14. General provisions
          </Typography>
          <Typography variant="h6" component="h3">
            {" "}
            14.1 Relationship of the parties
          </Typography>
          <ul>
            <Li>
              The parties will act solely as independent contractors. These
              Terms shall not be construed as creating any agency, partnership,
              joint venture, fiduciary duty, or any other form of legal
              association between the Client and Company.
            </Li>
          </ul>
          <Typography variant="h6" component="h3">
            {" "}
            14.2 Severability
          </Typography>
          <ul>
            <Li>
              If any term, condition or provision of these Terms is held to be
              invalid, unenforceable or illegal in whole or in part for any
              reason, that provision shall be enforced to the maximum extent
              permissible so as to effect the intent of the parties.
            </Li>
          </ul>
          <Typography variant="h6" component="h3">
            14.3 Entire agreement
          </Typography>
          <ul>
            <Li>
              These Terms are the entire agreement between Client and Company
              regarding Client’s use of the Pepper Cloud Services and supersedes
              all prior and contemporaneous agreements, proposals or
              representations, written or oral, concerning its subject matter.
              Except as otherwise provided herein, no modification, amendment,
              or waiver of any provision of these Terms will be effective unless
              in writing and signed by the party against whom the modification,
              amendment or waiver is to be asserted.
            </Li>
          </ul>
          <Typography variant="h6" component="h3">
            14.4 Assignment
          </Typography>
          <ul>
            <Li>
              The Client may not, directly or indirectly, in whole or in part,
              by operation of law or otherwise, assign or transfer these Terms
              or delegate any of its rights and/or obligations under these Terms
              without Company’s prior written consent. Any attempted assignment,
              transfer or delegation without such prior written consent will be
              void and unenforceable.
            </Li>
          </ul>
          <Typography variant="h6" component="h3">
            14.5 No waiver
          </Typography>

          <ul>
            <Li>
              Failure of either Party to exercise or enforce any provision of or
              any of its rights under these Terms shall not be deemed a waiver
              of future enforcement of that or any other provision or right.
            </Li>
          </ul>
          <Typography variant="h6" component="h3">
            14.6 Notices
          </Typography>
          <ul>
            <Li>
              Except as otherwise specified in these Terms, all notices related
              to these Terms will be in writing and will be effective upon (a)
              personal delivery, (b) the second business day after mailing, or
              (c), except for notices of termination or an indemnifiable claim
              (“Legal Notices”), which shall clearly be identifiable as Legal
              Notices, the day of sending by email.
            </Li>
            <Li>
              Billing-related notices to you will be addressed to the relevant
              billing contact designated by you.
            </Li>
            <Li>
              All other notices to you will be addressed to the relevant
              Services system administrator designated by you.
            </Li>
          </ul>
          <br />
          <Typography variant="h5" component="h2">
            15. General terms
          </Typography>
          <ul>
            <Li>
              The Service can be used for the transmission of the Client’s data
              and during processing, the data may be transferred unencrypted
              over the internet.
            </Li>
            <Li>
              The Company uses third party vendors and hosting partners to
              provide the necessary hardware, software, information, networking,
              storage, and related technology to run the service.
            </Li>
            <Li>
              The Client is not permitted to store, host, or send unsolicited
              emails, messages or chats. The Company is anti-spam compliant and
              does not authorise the Client to send spam. In such instances, the
              Client’s account might get suspended without prior notice.
              Accidental spam must immediately be reported to the Company to
              prevent account suspension.
            </Li>
            <Li>
              The Company makes no warranties regarding (i) your ability to use
              the Service, (ii) your satisfaction with the Service, (iii) that
              the Service will be available at all times, uninterrupted, and
              error-free (iv), the accuracy of mathematical calculations
              performed by the Service, and (v) that bugs or errors in the
              Service will be corrected.
            </Li>
            <Li>
              The Company, its affiliates, and its sponsors are neither
              responsible nor liable for any direct, indirect, incidental,
              consequential, special, exemplary, punitive, or other damages
              arising out of or relating in any way to your use of the Service.
              Your sole remedy for dissatisfaction with the Service is to stop
              using the Service.
            </Li>
            <Li>
              If any provision of the Terms of Service is held invalid or
              otherwise unenforceable, the enforceability of the remaining
              provisions shall not be impaired thereby.
            </Li>
            <Li>
              The Terms of Service sets forth the entire understanding between
              Client and the Company as to the Service and supersedes any prior
              agreements between the Client and the Company (including, but not
              limited to, prior versions of the Terms of Service).
            </Li>
          </ul>
          <br />
          <Typography variant="h5" component="h2">
            16. Governing law
          </Typography>
          <ul>
            <Li>
              These Terms shall be governed by the laws of Singapore without
              regard to the principles of conflicts of law. You hereby expressly
              agree to submit to the exclusive personal jurisdiction of the
              federal and state courts of Singapore for the purpose of resolving
              any dispute relating to your access to or use of the Service.
            </Li>
          </ul>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default TermsPage;
